import React, {Component} from 'react';

class ConnectButton extends Component {
    render() {
        return (
            <div className="col-md-8 mb-0 heading-section text-center">
                <p className="mb-4">{this.props.connBtnTitle}</p>
                <button onClick={this.props.connBtnLink}  className="btn btn-info py-3 px-4" target={"_blank"}>{this.props.connBtnDesc}</button>
                {this.props.connBtnBlockchainErroMsg !== "" ? (
                    <>
                        <div></div>
                        <div className="mt-4 alert alert-danger" role="alert">{this.props.connBtnBlockchainErroMsg}</div>
                    </>
                ) : null}
    
            </div>
        );
    }
}

export default ConnectButton;