import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import SectionConio from './components/conio';
import SectionManutenzione from './components/manutenzione';
import Estratto2 from './components/estratto2';
import SectionPiedone from './components/piedone';
import SectionCountdown from './components/countdown';
import Navbar from './components/navbar';
import Intestazione from './components/intestazione';

import logo from './images/Logo-Eliosfera.png';
import intestazioneBackgroundImage from './images/toward-hemelslinn-sfondo.jpg';
import conioBackgroundImage from './images/background-pixel-black-40.png';

import imgCarosello01 from './images/carosello/Carosello-01.jpg';
import imgCarosello02 from './images/carosello/Carosello-02.jpg';
import imgCarosello03 from './images/carosello/Carosello-03.jpg';
import imgCarosello04 from './images/carosello/Carosello-04.jpg';
import imgCarosello05 from './images/carosello/Carosello-05.jpg';
import imgCarosello06 from './images/carosello/Carosello-06.jpg';
import imgCarosello07 from './images/carosello/Carosello-07.jpg';
import imgCarosello08 from './images/carosello/Carosello-08.jpg'; 

import backgroundEstrattoImage3 from './images/join-our-discord.jpg';

const intestazioneBackgroundStyle = {backgroundImage: `url(${intestazioneBackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundAttachment: 'fixed', height: '300px'};
const conioBackgroundPixel = {backgroundImage: `url(${conioBackgroundImage})`,};
const caroselloStyle = {display: 'block', width: 400, paddin: 30,};

const EstrattoStyle3 = {backgroundImage: `url(${backgroundEstrattoImage3})`,};

//===[ SEZIONE COUNTDOWN ]=======================================================================

var manutenzione = true; //da impostare false per la produzione. Permette di mettere in pausa il sito. L’impostazione ha la precedenza su tutte le altre.
var mintAttivo = false; //da impostare true per la produzione. Se true il mint è attivo. Quindi la fase di conio è attiva. Se countdownAttivo è true, verrà visualizzato ugualmente il countdown.
var countdownAttivo = false; //da impostare per avviare il countdown per la produzione
var dataCountdown = "2023-06-30 13:00 UTC"; // data da impostare per la produzione la data sarà in UTC

//===[ FINE SEZIONE COUNTDOWN ]==================================================================

/*
NOTA: Quando si cambia da whitelist a publico e viceversa, è necessario cambiare alcune descrizioni.
In countdown.js esegui le modifiche sotto riportate:
Testi per la PREVENDITA:
	The THL NFTs presale minting has started!
	The presale of THL NFTs will start in

Testi per la VENDITA PUBBLICA:
	The THL NFTs public minting has started!
	Public minting of THL NFTs will start in

*/
//Sostituisci anche queste costanti:
/*
const conioTitolo="Presale Mint Toward Hemelslinn NFT";
const connTitle="Presale is active!";
const fineVenditaTitolo = "The THL NFTs presale has ended.";
*/

const conioTitolo="Public Mint Toward Hemelslinn NFT";
const connTitle="Public sale is active!";
const fineVenditaTitolo = "The THL NFTs public sale has ended.";




/*const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;*/

export function AddLibrary(urlOfTheLibrary) {
	const script = document.createElement('script');
	script.src = urlOfTheLibrary;
	script.async = true;
	document.body.appendChild(script);
}
	

function App() {
	const carouselImages = [
		{id: 0, immagine: imgCarosello01, altdesc: "Immagine 1", titleslide: "", textDesc: "", interval: "2000"},
		{id: 1, immagine: imgCarosello02, altdesc: "Immagine 2", titleslide: "", textDesc: "", interval: "2000"},
		{id: 2, immagine: imgCarosello03, altdesc: "Immagine 3", titleslide: "", textDesc: "", interval: "2000"},
		{id: 3, immagine: imgCarosello04, altdesc: "Immagine 4", titleslide: "", textDesc: "", interval: "2000"},
		{id: 4, immagine: imgCarosello05, altdesc: "Immagine 5", titleslide: "", textDesc: "", interval: "2000"},
		{id: 5, immagine: imgCarosello06, altdesc: "Immagine 6", titleslide: "", textDesc: "", interval: "2000"},
		{id: 6, immagine: imgCarosello07, altdesc: "Immagine 7", titleslide: "", textDesc: "", interval: "2000"},
		{id: 7, immagine: imgCarosello08, altdesc: "Immagine 8", titleslide: "", textDesc: "", interval: "2000"},
	];

	const aNavbarLinks = [
		{id: 0, linkHref: "https://towardhemelslinn.io", linkNome: "HOME"},
		{id: 1, linkHref: "https://novel.towardhemelslinn.io", linkNome: "NOVEL"},
		{id: 2, linkHref: "https://galaxias.towardhemelslinn.io/", linkNome: "GALAXIAS"},
		{id: 3, linkHref: "https://ebooks.towardhemelslinn.io/", linkNome: "DOWNLOAD EBOOKS"},
		{id: 4, linkHref: "https://towardhemelslinn.io/howtomint", linkNome: "HOW TO MINT"},
	]; 

	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	
	const [mintAmount, setMintAmount] = useState(1);

	// Modificare la configurazione a secondo del tipo di conio prevendita/pubblico
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "0x94f278e3A491A5b8E668e81d816AFa394c6d6647",
		SCAN_LINK: "https://etherscan.io/address/0x94f278e3A491A5b8E668e81d816AFa394c6d6647",
		NETWORK: {
			NAME: "Mainnet Ethereum Network",
			SYMBOL: "ETH",
			ID: 1,
		},
		NFT_NAME: "Toward Hemelslinn NFT",
		SYMBOL: "THL",
		MAX_SUPPLY: 10000,
		MAX_QUANTITA_CONIO: 20,
		WEI_COST: 120000000000000000,	//Durante la prevendita: 80000000000000000, durante il pubblico: 120000000000000000
		DISPLAY_COST: 0.12,				//Durante la prevendita: 0.08, Durante il pubblico: 0.12
		GAS_LIMIT: 3000000,
		MARKETPLACE: "OPENSEA",
		MARKETPLACE_LINK: "https://opensea.io/collection/toward-hemelslinn-nft"
 	});
	
	const [feedback, setFeedback] = useState(`Click [MINT ${CONFIG.SYMBOL}] to mint your NFT.`); //${CONFIG.NETWORK.SYMBOL}
	const [totalPrice, setTotalPrice] = useState(CONFIG.DISPLAY_COST);

	const mintTHL = () => {
		let cost = CONFIG.WEI_COST;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(cost * mintAmount);
		let totalGasLimit = String(gasLimit * mintAmount);
		console.log("Price: ", totalCostWei);
		console.log("Gas limit: ", totalGasLimit);
		console.log("Blockchain account: ", blockchain.account);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
		.mintTHL(mintAmount)
		.send({
			gasLimit: String(totalGasLimit),
			to: CONFIG.CONTRACT_ADDRESS,
			from: blockchain.account,
			value: totalCostWei,
		})
		.once("error", (err) => {
			console.log(err);
			setFeedback("Sorry, something went wrong please try again later.");
			setClaimingNft(false);
		})
		.then((receipt) => {
			console.log(receipt);
			setFeedback(
				`Congratulations! You can see your ${CONFIG.NFT_NAME} on <a href="${CONFIG.MARKETPLACE_LINK}" target="_blank">${CONFIG.MARKETPLACE}</a>.`
			);
			setClaimingNft(false);
			dispatch(fetchData(blockchain.account));
		});
	};


	const maxQuantitaConio = () => {
		setTotalPrice(CONFIG.DISPLAY_COST * CONFIG.MAX_QUANTITA_CONIO);
		setMintAmount(CONFIG.MAX_QUANTITA_CONIO);
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setTotalPrice(CONFIG.DISPLAY_COST * newMintAmount);
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > CONFIG.MAX_QUANTITA_CONIO) {
		newMintAmount = CONFIG.MAX_QUANTITA_CONIO;
		}
		setTotalPrice(CONFIG.DISPLAY_COST * newMintAmount);
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.smartContract !== null) {
		dispatch(fetchData(blockchain.account));
		} else {
		dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	const orarioAttuale = new Date().getTime();
	const orarioCountdown = new Date(dataCountdown).getTime();

	if (countdownAttivo) {
		if (orarioAttuale >= orarioCountdown) {
			//alert("Orario countdown è passato: "+dataCountdown+' - Orario countdown: '+orarioCountdown+' - Orario attuale: '+orarioAttuale+' - ContdownAttivo: '+countdownAttivo);
			countdownAttivo = false;
			//alert('Contdown Attivo: '+countdownAttivo);
		} else {
			//alert("Il countdown non è stato ancora raggiunto: "+dataCountdown+' - Contdown Attivo: '+countdownAttivo+' - Orario countdown: '+orarioCountdown+' - Orario attuale: '+orarioAttuale);
		}
	} else {
		//alert("Il countdown non è attivo: "+dataCountdown+' - Contdown Attivo: '+countdownAttivo);
	}


	return (
		<div className="page">
			<Navbar 
				linkSito='https://www.eliosfera.it'
				logo={logo}
				deskLogo='The Eliosfera publishing house logo'
				aNavbarLinks = {aNavbarLinks}
			/>
			<div id="pagina">

				<Intestazione
					pagina="MINT"
					backgroundStyle={intestazioneBackgroundStyle}
					titolo="TOWARD HEMELSLINN NFT"
					romanzo="an NFT collection based on a novel by"
					autore="Maurizio Feruglio"
					link_home="https://www.towardhemelslinn.io"
				/>
				


				{/*SEZIONE COUNTDOWN */}
				{manutenzione ? 
					<SectionManutenzione
						ManutenzioneTitolo='The public sale of the THL collection is, for the time being, suspended.
						On the new date check our Discord.'
						conioBackgroundPixel = {conioBackgroundPixel}     
						carouselImages = {carouselImages}
						caroselloStyle = {caroselloStyle}
					/> 
					:  mintAttivo ?
							countdownAttivo ?
							<>
								<SectionCountdown
									dataCountdown = {dataCountdown}
									conioTitolo={conioTitolo}
									conioTestVariabile={data.walletOfOwner}
									conioBackgroundPixel = {conioBackgroundPixel}    
									conioTotalSupply = {data.totalSupply}
									conioMaxSupply = {CONFIG.MAX_SUPPLY}
									conioLinkContract = {CONFIG.SCAN_LINK}
									conioTextContract = {CONFIG.CONTRACT_ADDRESS}
									conioNumberTotalSupply = {Number(data.totalSupply)}
									conioNote1 = "" //{`Make sure you are connecting to the right network: (${CONFIG.NETWORK.NAME}) and your correct wallet.`}
									conioNote2 = "" //{`We have set the gas limit to ${CONFIG.GAS_LIMIT} in order for your NFT to be successfully minted. We advise you not to lower this limit.`}
									fineVenditaTitolo = {fineVenditaTitolo}
									fineVenditaDesc = {`You can still find ${CONFIG.NFT_NAME} on`}
									fineVenditaLink = {CONFIG.MARKETPLACE_LINK}
									fineVenditaNomeMP = {CONFIG.MARKETPLACE}
									connTitle={connTitle}
									connDescr = {`1 ${CONFIG.NFT_NAME} costs ${CONFIG.DISPLAY_COST} ${CONFIG.NETWORK.SYMBOL} (excluding gas fee).`}
									connFTSymbol = {CONFIG.SYMBOL}
									connBlockchainAaccount = {blockchain.account}
									connBlockchainSmartContract = {blockchain.smartContract}
									connBtnTitle = {`Connect to the ${CONFIG.NETWORK.NAME} network`}
									connBtnDesc = "CONNECT"
									connBtnLink = {(e) => { e.preventDefault(); dispatch(connect()); getData(); }}
									connBtnBlockchainErroMsg = {blockchain.errorMsg}
									connGasLimit = {CONFIG.GAS_LIMIT}
									acqFeedBack = {feedback}
									acqMintAmount = {mintAmount}
									acqTotalPrice = {`Total Price: ${totalPrice > 0 ? Number((totalPrice).toFixed(2)) : CONFIG.DISPLAY_COST} ${CONFIG.NETWORK.SYMBOL}`}
									acqBtnDecrement = {(e) => { e.preventDefault(); decrementMintAmount(); }}
									acqBtnIncrement = {(e) => { e.preventDefault(); incrementMintAmount(); }}
									acqBtnMax = {(e) => { e.preventDefault(); maxQuantitaConio(); }}
									acqBtnMaxDesc = "      MAX      "
									acqBtnMintBusy = "      BUSY      "
									acqBtnMint = {`      MINT ${CONFIG.SYMBOL}    `}  //${CONFIG.NETWORK.SYMBOL} 
									acqBtnClaim = {(e) => { e.preventDefault(); mintTHL(); getData(); }} 
									acqClaimingNft = {claimingNft}  
									carouselImages = {carouselImages}
									caroselloStyle = {caroselloStyle}
								/>
		
							</>
							: 
								<SectionConio
									dataCountdown = {dataCountdown}
									conioTitolo={conioTitolo}
									conioTestVariabile={data.walletOfOwner}
									conioBackgroundPixel = {conioBackgroundPixel}    
									conioTotalSupply = {data.totalSupply}
									conioMaxSupply = {CONFIG.MAX_SUPPLY}
									conioLinkContract = {CONFIG.SCAN_LINK}
									conioTextContract = {CONFIG.CONTRACT_ADDRESS}
									conioNumberTotalSupply = {Number(data.totalSupply)}
									conioNote1 = "" //{`Make sure you are connecting to the right network: (${CONFIG.NETWORK.NAME}) and your correct wallet.`}
									conioNote2 = "" //{`We have set the gas limit to ${CONFIG.GAS_LIMIT} in order for your NFT to be successfully minted. We advise you not to lower this limit.`}
									fineVenditaTitolo = {fineVenditaTitolo}
									fineVenditaDesc = {`You can still find ${CONFIG.NFT_NAME} on`}
									fineVenditaLink = {CONFIG.MARKETPLACE_LINK}
									fineVenditaNomeMP = {CONFIG.MARKETPLACE}
									connTitle={connTitle}
									connDescr = {`1 ${CONFIG.NFT_NAME} costs ${CONFIG.DISPLAY_COST} ${CONFIG.NETWORK.SYMBOL} (excluding gas fee).`}
									connFTSymbol = {CONFIG.SYMBOL}
									connBlockchainAaccount = {blockchain.account}
									connBlockchainSmartContract = {blockchain.smartContract}
									connBtnTitle = {`Connect to the ${CONFIG.NETWORK.NAME} network`}
									connBtnDesc = "CONNECT"
									connBtnLink = {(e) => { e.preventDefault(); dispatch(connect()); getData(); }}
									connBtnBlockchainErroMsg = {blockchain.errorMsg}
									connGasLimit = {CONFIG.GAS_LIMIT}
									acqFeedBack = {feedback}
									acqMintAmount = {mintAmount}
									acqTotalPrice = {`Total Price: ${totalPrice > 0 ? Number((totalPrice).toFixed(2)) : CONFIG.DISPLAY_COST} ${CONFIG.NETWORK.SYMBOL}`}
									acqBtnDecrement = {(e) => { e.preventDefault(); decrementMintAmount(); }}
									acqBtnIncrement = {(e) => { e.preventDefault(); incrementMintAmount(); }}
									acqBtnMax = {(e) => { e.preventDefault(); maxQuantitaConio(); }}
									acqBtnMaxDesc = "      MAX      "
									acqBtnMintBusy = "      BUSY      "
									acqBtnMint = {`      MINT ${CONFIG.SYMBOL}    `}  //${CONFIG.NETWORK.SYMBOL} 
									acqBtnClaim = {(e) => { e.preventDefault(); mintTHL(); getData(); }} 
									acqClaimingNft = {claimingNft}  
									carouselImages = {carouselImages}
									caroselloStyle = {caroselloStyle}
								/>
						:
						<SectionManutenzione
							ManutenzioneTitolo="MINTING NOT ACTIVE"
							conioBackgroundPixel = {conioBackgroundPixel}   
							carouselImages = {carouselImages}
							caroselloStyle = {caroselloStyle}
						/>
				}


				{/* SECTION ESTRATTO 2 */}
				<Estratto2
					estrattoId="discord"
					estrattoStile={EstrattoStyle3}
					estrattoTitolo="JOIN OUR COMMUNITY"
					estrattoContenuto="Join the Discord THL Community and become a part of <em>Galaxias</em>. An exciting adventure awaits you!"
					estrattoButton="JOIN OUR DISCORD"
					estrattoButtonLink='https://discord.gg/bWJ9nDthpA'
				/>

				<SectionPiedone
					piedoneLinkHowToMint='https://towardhemelslinn.io/howtomint'
					piedoneLinkProvenance='https://towardhemelslinn.io/provenance'
					piedoneLinkEbooksProvenance='https://towardhemelslinn.io/ebooksprovenance'
					piedoneLinkWhitePaper='https://whitepaper.towardhemelslinn.io'
					piedoneLinkTermConditions='https://towardhemelslinn.io/termsandconditions'
					piedoneLinkPrivacy='https://towardhemelslinn.io/privacy'
					piedoneLinkSmartContracts='https://towardhemelslinn.io/smartcontracts'
					piedoneIframe=''
					piedoneTwitter='https://twitter.com/TowardHemelslin'
					piedoneInstagram=''
					piedoneFacebook=''
					piedoneDiscord='https://discord.gg/bWJ9nDthpA'
					piedonePinterest=''
					piedoneOpensea='https://opensea.io/collection/toward-hemelslinn-nft'
					piedoneYoutube='https://www.youtube.com/@TowardHemelslinnNFT'
					piedoneCopyright="Eliosfera Editrice &copy;"
					piedoneCopyrightDisclaimer="All rights reserved"
					piedoneCCBY='This template is built from a base of <i className="icon-heart" aria-hidden="true"></i> <a href="https://colorlib.com" target="_blank" rel="noreferrer noopener">Colorlib</a>'
				/>
			</div>

			{AddLibrary(
				'js/jquery.min.js')}
			{AddLibrary(
				'js/jquery-migrate-3.0.0.min.js')}
			{AddLibrary(
				'js/popper.min.js')}
			{AddLibrary(
				'js/bootstrap.min.js')}
			{AddLibrary(
				'js/jquery.easing.1.3.js')}
			{AddLibrary(
				'js/jquery.waypoints.min.js')}	
			{AddLibrary(
				'js/jquery.stellar.min.js')}	
			{AddLibrary(
				'js/owl.carousel.min.js')}
			{AddLibrary(
				'js/jquery.magnific-popup.min.js')}
			{AddLibrary(
				'js/aos.js')}
			{AddLibrary(
				'js/jquery.animateNumber.min.js')}
			{AddLibrary(
				'js/scrollax.min.js')}		
			{AddLibrary(
				'js/jquery.mb.YTPlayer.min.js')}
			{AddLibrary(
				'js/bootstrap-datepicker.js')}		
			{AddLibrary(
				'js/jquery.timepicker.min.js')}
			{AddLibrary(
				'js/main.js')}
		</div>
	);
}

export default App;
