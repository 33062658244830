import React, {Component} from 'react';
import { ReactComponent as OpenseaIcon } from "../images/Opensea-white.svg";
import { SiDiscord } from "react-icons/si";
import { SiInstagram } from "react-icons/si";
import { SiTwitter } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiPinterest } from "react-icons/si";
import { SiYoutube } from "react-icons/si";
import { ReactComponent as XIcon } from "../images/x-twitter-white.svg";

class SectionPiedone  extends Component {
    render() {
        const current = new Date();
        /*const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;*/
        let twitter;
        let discord;
        let instagram;
        let facebook;
        let pinterest;
        let opensea;
		let youtube;

        if (this.props.piedoneTwitter) {
            twitter = (
				<li className="ftco-animatex"><a href={this.props.piedoneOpensea}><span style={{color: "red"}}><XIcon /></span></a></li>
            );
        } else {
            twitter = "";
        }
        if (this.props.piedoneDiscord) {
            discord = (
                <li className="ftco-animatex"><a href={this.props.piedoneDiscord}><span><SiDiscord size="1,5em" /></span></a></li> 
            );
        } else {
            discord = "";
        }
        if (this.props.piedoneInstagram) {
            instagram = (
                <li className="ftco-animatex"><a href={this.props.piedoneInstagram}><span><SiInstagram size="1,5em" /></span></a></li> 
            );
        } else {
            instagram = "";
        }
        if (this.props.piedoneFacebook) {
            facebook = (
                <li className="ftco-animatex"><a href={this.props.piedoneFacebook}><span><SiFacebook size="1,5em" /></span></a></li> 
            );
        } else {
            facebook = "";
        }
        if (this.props.piedonePinterest) {
            pinterest = (
                <li className="ftco-animatex"><a href={this.props.piedonePinterest}><span><SiPinterest size="1,5em" /></span></a></li> 
            );
        } else {
            pinterest = "";
        }
        if (this.props.piedoneOpensea) {
            opensea = (
                <li className="ftco-animatex"><a href={this.props.piedoneOpensea}><span style={{color: "red"}}><OpenseaIcon /></span></a></li> 
            );
        } else {
            opensea = "";
        }
        if (this.props.piedoneYoutube) {
            youtube = (
                <li className="ftco-animatex"><a href={this.props.piedoneYoutube}><span><SiYoutube size="1,5em" /></span></a></li> 
            );
        } else {
            youtube = "";
        }

        return (
            <section className="ftco-footer ftco-section img">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-0 heading-section text-center ftco-animatex">
                            <p> <br /></p>
                            <ul className="ftco-footer-social list-unstyled">
                                {twitter}
                                {discord}   
                                {instagram}
                                {facebook}
                                {pinterest}
                                {opensea}
								{youtube}
                            </ul>
                        </div>
                    </div>
                    <div className="row text-center ftco-animatex">
						<div className="col-md-12">
							<div className="ftco-footer-widget ">
								<h2 className="ftco-heading-2">Site Links</h2>
							</div>
						</div>
					</div>
					<div className="row text-center ftco-animatex">
						<div className="col-md-4 text-center">
							<div className="ftco-footer-widget mb-4 ml-md-4">
								<ul className="list-unstyled">
									<li><a href={this.props.piedoneLinkNFTsProvenance} className="py-0 d-block">NFTs Provenance</a></li>			
									<li><a href={this.props.piedoneLinkEbooksProvenance} className="py-0 d-block">Ebooks Provenance</a></li>
									<li><a href={this.props.piedoneLinkSmartContracts} className="py-0 d-block">Smart Contracts</a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-4 text-center">
							<div className="ftco-footer-widget mb-4 ml-md-4">
								<ul className="list-unstyled">	
								<li><a href={this.props.piedoneLinkWhitePaper} className="py-0 d-block">Whitepaper</a></li>
									<li><a href={this.props.piedoneLinkHowToMint} className="py-0 d-block">How to mint</a></li>			
								</ul>
							</div>
						</div>
						<div className="col-md-4 text-center">
							<div className="ftco-footer-widget mb-4 ml-md-4">
								<ul className="list-unstyled">			
									<li><a href={this.props.piedoneLinkPrivacy} className="py-0 d-block">Privacy Policy</a></li>
									<li><a href={this.props.piedoneLinkTermConditions} className="py-0 d-block">Terms & Conditions</a></li>
								</ul>
							</div>
						</div>
					</div>

					<div className="row mt-4 ftco-animatex">
                        <div className="col-md-12 text-center">
                            <p>{this.props.piedoneCopyright} {current.getFullYear()} - {this.props.piedoneCopyrightDisclaimer}</p>
                            <p dangerouslySetInnerHTML={{ __html: this.props.piedoneCCBY }}></p>
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}
export default SectionPiedone;