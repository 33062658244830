import React, {Component} from 'react';

class FineVendita extends Component {
    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-md-8 mb-0 heading-section text-center"> 
                    <h3 className="mb-4">{this.props.fineVenditaTitolo}</h3>
                    <p className="mb-5">{this.props.fineVenditaDesc}</p>
                    <p className="mb-5"><a href={this.props.fineVenditaLink} className="btn btn-info py-3 px-4" target={"_blank"}>{this.props.fineVenditaNomeMP}</a></p>
                </div>
            </div>
        );
    }
}

export default FineVendita;