import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.css';

class VSLCarousel extends Component {
    render() {

        const CarouselImages = this.props.carouselImages;
        return (
            <Container className="container text-center" style={this.props.caroselloStyle}>
                <Carousel>
                    {CarouselImages.map(carouselimages => (
                        <Carousel.Item key={carouselimages.id} interval={carouselimages.interval ? carouselimages.interval : 0}>
                            <img
                                key={carouselimages.id}
                                className="d-block w-100"
                                src={carouselimages.immagine}
                                alt={carouselimages.altdesc}
                            />
                            <Carousel.Caption>
                                <h3>{carouselimages.titleslide}</h3>
                                <p>{carouselimages.textDesc}</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        );
    }
}

export default VSLCarousel;