import React, {Component} from 'react';

class Intestazione extends Component {
    render() {
        return (
            <section id="home" className="hero-wrap" style={this.props.backgroundStyle}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters text align-items-end justify-content-center">
                        <div className="col-md-8 text-center">
                            <p className="breadcrumbs"><span className="mr-2"><a href={this.props.link_home}>Home</a></span> <span>{this.props.pagina}</span></p>
                            <h1 className="mb-5 bread">{this.props.titolo}</h1>
                            <br /><span className="subheading">{this.props.romanzo}</span>
                            <h3 className="mb-4">{this.props.autore}</h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default Intestazione;