import React, {Component} from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import VSLCarousel from './carosello';


class SectionManutenzione  extends Component {
    render() {
        return (
            <section className="" style={this.props.conioBackgroundPixel}>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
							<VSLCarousel 
								carouselImages = {this.props.carouselImages}
								caroselloStyle = {this.props.caroselloStyle}
							/>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col id="minting" className="text-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="mb-0 heading-section text-center">
                                        <h2 className="mb-4 mt-5">{this.props.ManutenzioneTitolo}</h2>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
export default SectionManutenzione;