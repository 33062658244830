import React, {Component} from 'react';

class AcquistoToken extends Component {
    render() {
        return (
            <div className="col-md-10 mb-0 heading-section text-center">
                <div className="mb-3 alert alert-info" role="alert" dangerouslySetInnerHTML={{ __html: this.props.acqFeedBack }} ></div>
                
                <div className="row justify-content-center">
                    <div className="col-md-4 appointment">
                        <div className="row">
                            <div className="col-md-4 justify-content-center"> 
                                    <div className="form-group justify-content-center">
                                        <button onClick={this.props.acqBtnDecrement} disabled={this.props.acqClaimingNft ? 1 : 0} className="btn btn-info py-3 px-4"> - </button>
                                    </div>
                            </div>
                            <div className="col-md-4 justify-content-center">
                                    <div className="form-group justify-content-center">
                                    <p className="alert alert-info py-3">{this.props.acqMintAmount}</p>
                                    </div>
                            </div>
                            <div className="col-md-4 justify-content-center">
                                    <div className="form-group justify-content-center">
                                        <button onClick={this.props.acqBtnIncrement} disabled={this.props.acqClaimingNft ? 1 : 0} className="btn btn-info py-3 px-4"> + </button>
                                    </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="d-md-flex justify-content-center">
                                <div className="justify-content-center mb-4">
                                    <button onClick={this.props.acqBtnMax} disabled={this.props.acqClaimingNft ? 1 : 0} className="btn btn-info py-3 px-4">{this.props.acqBtnMaxDesc}</button>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="d-md-flex justify-content-center">
                                <div className="justify-content-center">
                                    <button onClick={this.props.acqBtnClaim} disabled={this.props.acqClaimingNft ? 1 : 0} className="btn btn-info py-3 px-4">{this.props.acqClaimingNft ? this.props.acqBtnMintBusy : this.props.acqBtnMint }</button>
                                </div>
                            </div>
                        </div>
                        <div className="justify-content-center">{this.props.acqTotalPrice}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AcquistoToken;