import React, {Component, useState} from 'react';
import Countdown from 'react-countdown';

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
	if (completed) {
		window.location.reload(true); //Riavvio la pagina
		window.
	  	// Render a complete state
		return (
			<><h3 className="mb-0">The THL NFTs public minting has started!</h3></>	
		)

	} else {
	  // Render a countdown
	  return (
			<>
				<h2 className="mb-4">Public minting of THL NFTs will start in</h2>
 				<div className="row">
					<div className="col-md-3 justify-content-center"> 
						<div className="form-group justify-content-center">
							<h1 className="countdown">{days}</h1>
							<h3 className="mb-0">Days</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown">{hours}</h1>
							<h3 className="mb-0">Hours</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown"> {minutes}</h1>
							<h3 className="mb-0">Minutes</h3>
						</div>
					</div>
					<div className="col-md-3 justify-content-center">
						<div className="form-group justify-content-center">
							<h1 className="countdown">{seconds}</h1>
							<h3 className="mb-0">Seconds</h3>
						</div>
					</div>
				</div>
			</>
	  	);
	}
  };

export class SectionCountdown extends Component {
    constructor(props){
        super(props)
		this.renderer = this.props
    }

    render() {
        return (
            <section className="">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mb-0 heading-section text-center ftco-animatex">
							<Countdown date={(new Date(this.props.dataCountdown)).getTime() } renderer={renderer} />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

}
export default SectionCountdown;