import React, {Component} from 'react';
import FineVendita from './finevendita';
import ConnessionePortafoglio from './connessioneportafoglio';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import VSLCarousel from './carosello';


class SectionConio  extends Component {
    render() {
        return (
			
            <section className="" style={this.props.conioBackgroundPixel}>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
							<VSLCarousel 
								carouselImages = {this.props.carouselImages}
								caroselloStyle = {this.props.caroselloStyle}
							/>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col id="minting" className="text-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="mb-0 heading-section text-center">
                                        <h2 className="mb-4 mt-5">{this.props.conioTitolo}</h2>
										{this.props.connBlockchainAaccount === "" || this.props.connBlockchainSmartContract === null ? (
											<h2 className="mb-4">&nbsp;</h2>
										) : (
											<h2 className="mb-4">&nbsp;</h2>
											//<h2 className="mb-4">{this.props.conioTotalSupply} / {this.props.conioMaxSupply}</h2>
										)}
                                    </div>
                                </div>
                                {this.props.conioNumberTotalSupply >= this.props.conioMaxSupply ? (
                                    <>
                                        <FineVendita
                                            fineVenditaTitolo = {this.props.fineVenditaTitolo}
                                            fineVenditaDesc = {this.props.fineVenditaDesc}
                                            fineVenditaLink = {this.props.fineVenditaLink}
                                            fineVenditaNomeMP = {this.props.fineVenditaNomeMP}
                                        />

                                    </>
                                ) : (
                                    <>
                                        <ConnessionePortafoglio
											dataCountdown = {this.props.dataCountdown}
                                            connTitle = {this.props.connTitle}
                                            connDescr = {this.props.connDescr}
                                            connNFTSymbol = {this.props.connFTSymbol}
                                            connBlockchainAaccount = {this.props.connBlockchainAaccount}
                                            connBlockchainSmartContract = {this.props.connBlockchainSmartContract}
                                            connBtnBlockchainErroMsg = {this.props.connBtnBlockchainErroMsg}
                                            connBtnTitle = {this.props.connBtnTitle}
                                            connBtnDesc = {this.props.connBtnDesc}
                                            connBtnLink = {this.props.connBtnLink}
                                            acqFeedBack = {this.props.acqFeedBack}
                                            acqMintAmount = {this.props.acqMintAmount}
                                            acqBtnDecrement = {this.props.acqBtnDecrement}
                                            acqBtnIncrement = {this.props.acqBtnIncrement}
                                            acqBtnClaim = {this.props.acqBtnClaim} 
                                            acqTotalPrice = {this.props.acqTotalPrice}
                                            acqBtnMax = {this.props.acqBtnMax}
                                            acqBtnMaxDesc = {this.props.acqBtnMaxDesc}
                                            acqClaimingNft = {this.props.acqClaimingNft}
                                            acqBtnMintBusy = {this.props.acqBtnMintBusy}
                                            acqBtnMint = {this.props.acqBtnMint}
                                        />
                                        <div className="row justify-content-center">
                                            <h4 className="mb-0"><a href={this.props.conioLinkContract} className="btn" target={"_blank"}><span className="badge bg-secondary">Smart contract:<br />{this.props.conioTextContract}</span></a></h4>
                                        </div>
                                        <div className="text text-center">
                                            <p dangerouslySetInnerHTML={{ __html: this.props.conioNote1 }}></p>
                                            <p dangerouslySetInnerHTML={{ __html: this.props.conioNote2 }}></p>
                                        </div>
                                    </> 
                                )}
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
export default SectionConio;