import React, {Component} from 'react';
import ConnectButton from './pulsanteconnessione';
import AcquistoToken from './acquistotoken';
import SectionCountdown from './countdown';

class ConnessionePortafoglio extends Component {
    render() {
        return (
            <div className="row justify-content-center">
                <div className="col-md-8 mb-0 heading-section text-center"> 
                    <div className="overlay"></div>    
                    <p className="mb-3">{this.props.connDescr}</p>
                </div>
                {this.props.connBlockchainAaccount === "" || this.props.connBlockchainSmartContract === null ? (
                    <>
                        <ConnectButton
                            connBtnTitle = {this.props.connBtnTitle}
                            connBtnLink = {this.props.connBtnLink}
                            connBtnDesc = {this.props.connBtnDesc}
                            connBtnBlockchainErroMsg = {this.props.connBtnBlockchainErroMsg}
                        />
                    </>
                ) : (
                    <>
                        <AcquistoToken
                            acqFeedBack = {this.props.acqFeedBack}
                            acqBtnDecrement = {this.props.acqBtnDecrement}
                            acqBtnIncrement = {this.props.acqBtnIncrement}
                            acqMintAmount = {this.props.acqMintAmount}
                            acqBtnClaim = {this.props.acqBtnClaim}
                            acqBtnMax = {this.props.acqBtnMax}
                            acqBtnMaxDesc = {this.props.acqBtnMaxDesc}
                            acqTotalPrice = {this.props.acqTotalPrice}
                            acqClaimingNft = {this.props.acqClaimingNft}
                            acqBtnMintBusy = {this.props.acqBtnMintBusy}
                            acqBtnMint = {this.props.acqBtnMint}
                        />
                    </>
          
                )}
            </div>
            
        );
    }
}

export default ConnessionePortafoglio;